<template>
<div class='main'>
  <div class="head">
    <ul>
      <li v-for="(item,index) in headWenzi" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.name}}</i>
      </li>
      <li><a @click="this.$router.push('/coffee/user')">会员</a></li>
      <li><a @click="this.$router.push('/coffee')">咖啡社区</a></li>
      <li><a @click="this.$router.push('/live')">直播</a></li>
    </ul>
  </div>

  <div class="body">
    <!-- <div class="body_title">
      <img class="logo" src="../../static/ossImg/logo.png" alt="">
      <img class="logo" src="../../static/ossImg/logo.png" alt="">
    </div> -->

    <div class="sear_title">
      <el-tabs
        v-model="activeName"
        type="card"
        class="demo-tabs"
      >
      <el-tab-pane label="会员中心" name="user" v-loading="loading">
        <div class="sear_body">
          <div class="user_msg">
            <img :src="umsMember.icon" alt="">
            <h3>
              <p>{{umsMember.realName}}</p>
              <!-- <span>积分值：250</span> -->
            </h3>
          </div>
          <el-divider />
          <h2>内容管理</h2>
          <div class="cont_neirong">
            <div>
              <el-tabs v-model="neirongActName" @tab-click="handleClick"
              class="demo-tabs">
                <!-- <el-tab-pane label="动态" name="dongtai">User</el-tab-pane> -->
                <!-- <el-tab-pane label="评论" name="pinglun">
                  <h4>我的评论</h4>
                  <el-divider />
                  <div class="ping_cont_list" v-loading="loading"
                  v-for="(i,k) in pinglunList.postCommentEntitys" :key="k">
                    <p style="color: #3395F1;">{{i.memberNickName}}</p>
                    <p>
                      <span class="star"><el-icon><magic-stick /></el-icon></span>
                      {{i.content}}
                    </p>
                    <p style="color: #999999;">{{i.createTime}}</p>
                    <el-divider />
                  </div>
                </el-tab-pane> -->
                <el-tab-pane label="文章" name="wenzhang">
                  <h4>
                    <span class="my_name">我的文章</span>
                    <!-- <el-button size="small" @click="toPublications">发表文章</el-button> -->
                  </h4>
                  <el-divider />
                  <div class="ping_cont_list" v-loading="loading"
                    v-for="(i,k) in wenzhangList.postEntities" :key="k"
                    >
                    <p @click="tieziDetails(i)" style="color: #3395F1;">{{i.title}}</p>
                    <div class="delete_icon">
                      <el-icon @click.stop="deleteTiezi(i, k)" :size="20"><DeleteFilled /></el-icon>
                    </div>
                    <div @click="tieziDetails(i)">
                      <!-- <span class="star"></span> -->
                      <span class="span_html">{{i.allcont}}</span>
                      <span class="span_html" v-if="i.showStatus" v-html="i.content"></span>
                      <div class="div_img" v-if="i.allpic">
                        <img v-for="(j,p) in i.allpic" :key="p" :src="j">
                      </div>
                    </div>
                    <p style="color: #999999;">{{i.establishTime}}</p>
                    <el-divider />
                  </div>
                </el-tab-pane>
                <el-tab-pane label="收藏" name="shoucang">
                  <h4>
                    <span class="my_name">我的收藏</span>
                  </h4>
                  <el-divider />
                  <div class="ping_cont_list" v-loading="loading"
                    v-for="(i,k) in shoucangList.postEntities" :key="k"
                    @click="tieziDetails(i)">
                    <p style="color: #3395F1;">{{i.title}}</p>
                    <div>
                      <!-- <span class="star"></span> -->
                      <span class="span_html">{{i.allcont}}</span>
                      <span class="span_html" v-if="i.showStatus" v-html="i.content"></span>
                      <div class="div_img" v-if="i.allpic">
                        <img v-for="(j,p) in i.allpic" :key="p" :src="j">
                      </div>
                    </div>
                    <p style="color: #999999;">{{i.establishTime}}</p>
                    <el-divider />
                  </div>
                </el-tab-pane>
                <el-tab-pane label="点赞" name="dianzan">
                  <!-- <h4>我的点赞</h4>
                  <el-divider /> -->
                  <h4>
                    <span class="my_name">文章点赞</span>
                  </h4>
                  <el-divider />
                  <div class="ping_cont_list" v-loading="loading"
                    v-for="(i,k) in dianzanList.postEntities" :key="k"
                    @click="tieziDetails(i)">
                    <p style="color: #3395F1;">{{i.title}}</p>
                    <div>
                      <!-- <span class="star"></span> -->
                      <span class="span_html">{{i.allcont}}</span>
                      <span class="span_html" v-if="i.showStatus" v-html="i.content"></span>
                      <div class="div_img" v-if="i.allpic">
                        <img v-for="(j,p) in i.allpic" :key="p" :src="j">
                      </div>
                    </div>
                    <p style="color: #999999;">{{i.establishTime}}</p>
                    <el-divider />
                  </div>
                  <!-- <h4>评论点赞</h4>
                  <el-divider />
                  <div class="ping_cont_list" v-loading="loading"
                  v-for="(i,k) in dianzanList.postCommentEntitys" :key="k">
                    <p style="color: #3395F1;">点赞数：{{i.giveALike}}</p>
                    <p>
                      <span v-html="i.content"></span>
                    </p>
                    <p style="color: #999999;">{{i.createTime}}</p>
                    <el-divider />
                  </div> -->
                </el-tab-pane>
                <el-tab-pane label="关注" name="guanzhu">
                  <h4>
                    <span class="my_name">我的关注</span>
                  </h4>
                  <el-divider />
                  <div>
                    <div class="ping_cont_list_guanzhu" v-loading="loading"
                    v-for="(i,k) in guanzhuList" :key="k">
                      <img :src="i.icon" alt="">
                      <div>
                        <p style="color: #3395F1;">{{i.realName}}</p>
                        <el-button size="small" @click="unFllowUser(i,k)">取消关注</el-button>
                      </div>
                    </div>
                  <el-divider />

                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="divfooter">
    <Footer/>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../api/http'
import Footer from '../index/Footer.vue'
import { ElMessage } from 'element-plus'

// 会员中心信息
const queryMemberCentre = (data) => {
  return http.get(`/mall-portal/mall/post/queryMemberCentre/${data.contentManagement}`, data)
}
// 删除帖子
const deleteTiezi = (data) => {
  return http.post('/mall-portal/mall/post/delete', data, true)
}
// 取消关注人气用户
const unfollowPopularUsers = (data) => {
  return http.post('/mall-portal/mall/post/unfollowPopularUsers', data)
}

const headWenzi = [
  { name: '首页', path: '/' },
  { name: '资讯', path: '/coffee/information' }

  // { name: '直播', path: '/coffee' },
  // { name: '咖啡社区', path: '/coffee' },
  // { name: '会员', path: '/coffee/user' }
]
export default {
  name: '/coffee/user',
  data () {
    return {
      activeName: ref('user'),
      neirongActName: ref('wenzhang'), // 当前选择项
      loading: ref(false),
      umsMember: ref(''),

      // dongtaiList: ref(''),
      pinglunList: ref(''),
      wenzhangList: ref(''),
      shoucangList: ref(''),
      dianzanList: ref(''),
      guanzhuList: ref('')
    }
  },
  components: { Footer },
  methods: {
    handleClick (tab, event) {
      this.loading = true
      // console.log(tab.props.name)
      let status = ''
      switch (tab.props.name) {
        case 'dongtai':
          return
          // break
        case 'pinglun':
          status = 0
          break
        case 'wenzhang':
          status = 1
          break
        case 'shoucang':
          status = 2
          break
        case 'dianzan':
          status = 3
          break
        case 'guanzhu':
          status = 4
          break
        default:
          break
      }
      this.initData(status)
    },
    async initData (status) { // 查询会员中心信息
      this.loading = true
      const data = {
        contentManagement: status
      }
      const res = await queryMemberCentre(data)
      console.log(res)
      if (status !== 4) {
        this.umsMember = res.data.data.umsMember
      }
      // this.loading = false
      if (res.data.code === 200) {
        switch (status) {
          case 0: // 评论
            this.pinglunList = res.data.data
            break
          case 1: // 文章
            this.wenzhangList = res.data.data
            this.wenzhangList.postEntities.forEach(e => {
              // console.log(e)
              if (e.showStatus) {
                return
              }
              e.allcont = e.content.split('+-*/')[0]
              e.allpic = e.content.split('+-*/')[1].split(',')
            })
            break
          case 2: // 收藏
            this.shoucangList = res.data.data
            this.shoucangList.postEntities.forEach(e => {
              // console.log(e)
              if (e.showStatus) {
                return
              }
              e.allcont = e.content.split('+-*/')[0]
              e.allpic = e.content.split('+-*/')[1].split(',')
            })
            break
          case 3: // 点赞
            this.dianzanList = res.data.data
            this.dianzanList.postEntities.forEach(e => {
              // console.log(e)
              if (e.showStatus) {
                return
              }
              e.allcont = e.content.split('+-*/')[0]
              e.allpic = e.content.split('+-*/')[1].split(',')
            })
            break
          case 4: // 关注
            this.guanzhuList = res.data.data
            break
          default:
            break
        }
      }
      this.loading = false
    },
    tieziDetails (item, index) { // 查看帖子详情
      console.log(item)
      if (item.showStatus) {
        this.$router.push({
          path: '/coffee/informationArticle',
          query: {
            id: item.id
          }
        })
      } else {
        this.$router.push({
          path: '/coffee/communityarticle',
          query: {
            id: item.id
          }
        })
      }
    },
    deleteTiezi (item, index) { // 删除文章
      console.log(item)
      const data = [item.id]
      this.$confirm('您确定要删除此文章吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const res = deleteTiezi(data)
          res.then(resj => {
            if (resj.data.code === 200) {
              ElMessage.success({
                message: resj.data.msg
              })
              this.wenzhangList.postEntities.splice(index, 1)
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch(() => {})
    },
    async unFllowUser (item, index) { // 取关用户
      console.log(item)
      const data = {
        popularUsersId: item.id
      }
      const res = await unfollowPopularUsers(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
        this.guanzhuList.splice(index, 1)
      }
    }
  },
  mounted () {
    this.initData(1)
  },
  setup (props, ctx) {
    return {
      headWenzi
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.main{
  width: 100%;
  background: #f2efed;
  // height: 800px;
  font-family: Microsoft YaHei;
  color: #333333;
  .head{
    height: 30px;
    background: #FFFFFF;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      font-size: 14px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        color: #333333;
        cursor: pointer;
        .na-text{
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
      li:nth-last-child(3),li:nth-last-child(2),li:last-child{
        float: right;
        a{
          color: #666;
        }
      }
    }
  }
  .body{
    width: 1200px;
    background: #FFFFFF;
    margin: 0 auto;
    margin-top: 20px;
    .body_title{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      img{
        width: 165px;
        height: 135px;
      }
    }
    .sear_title{
      min-height: 800px;
      .el-tabs{
        .el-tabs__content{
          #pane-user{
            .sear_body{
              .user_msg{
                padding: 0 40px;
                display: flex;
                align-items: center;
                img{
                  width: 148px;
                  height: 148px;
                  border-radius: 50%;
                }
                h3{
                  margin-left: 40px;
                  line-height: 50px;
                  p{
                    font-weight: bold;
                    color: #333333;
                    font-size: 29px;
                  }
                  span{
                    font-weight: 400;
                    font-size: 25px;
                    color: #C42420;
                  }
                }
              }
              h2{
                height: 40px;
                line-height: 40px;
                font-size: 25px;
                font-weight: bold;
                color: #BC0500;
              }
              .cont_neirong{
                display: flex;
                padding: 0 30px;
                div{
                  width: 100%;
                  padding:0 10px;
                  flex: 1;
                  .el-tabs{
                    .el-tabs__content{
                      .el-tab-pane{
                        h4{
                          font-size: 25px;
                          display: flex;
                          align-items: center;
                          .my_name{
                            font-family: SimHei;
                            font-weight: 400;
                            color: #000000;
                          }
                          .el-button{
                            margin-left: 50px;
                            background: #FFB400;
                            color: #FFFEFE;
                            border-radius: 10px;
                          }
                        }
                        .ping_cont_list{
                          box-sizing: border-box;
                          position: relative;
                          .delete_icon{
                            width: 30px;
                            position: absolute;
                            padding: 0;
                            top: 0;
                            right: 0;
                          }
                          img{
                            height: 80px;
                            width: 80px;
                          }
                          div{
                            cursor: pointer;
                            box-sizing: border-box;
                            .span_html{
                              display: inline-block;
                              height: 100px;
                              overflow: hidden;
                            }
                          }
                          p{
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            margin: 20px;
                            margin-bottom: 30px;
                            font-size: 21px;
                            font-weight: 400;
                            color: #666666;
                            .star{
                              margin-right:20px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              width: 96px;
                              height: 44px;
                              background: #FFFFFF;
                              border: 1px solid #999999;
                              border-radius: 5px;
                            }
                          }
                        }
                        .ping_cont_list_guanzhu{
                          // width: 100%;
                          display: flex;
                          align-items: center;
                          margin-bottom: 10px;
                          box-sizing: border-box;
                          img{
                            width: 120px;
                            height: 120px;
                          }
                          div{
                            flex: 1;
                            padding-left: 30px;
                            p{
                              font-size: 25px;
                              // flex: 1;
                            }
                            .el-button{
                              margin-top: 10px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .divfooter{
    background: #171717;
  }
}
</style>
